/* You can add global styles to this file, and also import other style files */

$darkgrey: #676c7b;
$white: #ffffff;
$brand: #4F2D7F;
$background: #f1f0ec;

h4 {
    font-weight: 400;
    font-size: 16px;
    color: #575962;
}

ngb-modal-window {
    // Fix for the modal being under the overlay. The overlay has a z-index of 1055.
    z-index: 1100 !important;
}

.m-topbar
    .m-topbar__nav.m-nav
    > .m-nav__item.m-topbar__user-profile.m-topbar__user-profile--img.m-dropdown--arrow
    .m-dropdown__arrow {
    color: $white;
}

.m-topbar
    .m-topbar__nav.m-nav
    > .m-nav__item
    > .m-nav__link
    .m-topbar__username {
    color: $white;
    text-transform: none;
    font-size: 1.1rem;
}

.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__name {
    color: $darkgrey;
}

.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__email {
    color: $darkgrey;
}

.m-topbar__arrow {
    margin-left: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    color: $white;
}

.m-topbar__logo {
    // background-color: $white;
}

.m-topbar .m-topbar__nav.m-nav > .m-nav__item.m-topbar__client-logo img {
    max-height: 40px !important;
    display: block;
    vertical-align: middle;
    margin: 15px;
}

.m-portlet .m-portlet__head-brand {
    justify-content: flex-start;
    background-color: $brand;
}

.m-portlet .m-portlet-clean{
    background-color: $white;
}

.m-portlet .m-portlet__head .m-portlet-clean .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
    color: $brand;
}

.m-portlet .m-portlet__head.m-portlet__head--noborder {
    border-bottom: 0;
}

.m-portlet-gt-product {
    float: left;
    width: 94%;
}

.m-portlet-gt-side {
    text-align: center;
    background-color: $brand;
    float: right;
    width: 6%;
    display: block;
    font-size: 2rem;
    font-weight: 500;
    color: white;
    margin-bottom: 2.2rem;
}

.m-portlet-gt-side__text {
    position: relative;
    top: 40%;
}

.m-portlet-gt-container {
    display: flex;
    height: 200px;
    max-height: 200px;
    cursor: pointer;
}

.gt-product-title {
    font-size: 18px;
    font-weight: 500;
    color: $brand;
}

// .m-portlet__head-caption {
//     padding-right: 10px;
//     border-right: 1px solid white;
// }

.m-portlet__nav-item.upload-wrapper {
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-flex;
        .btn {
            margin-top: 9px;
            border-left: 1px solid white;
            border-radius: 0;
            margin-left: 0.8rem;
        }
        input[type='file'] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

        &:hover {
          cursor: pointer;
        }
    }
}

.m-btn--icon.m-btn--icon-only {
    width: 20px;
    height: 20px;

}

.table .thead-primary {
    background-color: #f2f3f7;
}

.table-striped tbody tr:nth-child(odd) {
    background-color: $white;
}

.table-striped tbody tr:nth-child(even) {
    background-color: #f7f8f9;
}

.table-striped tr.uploading {
    opacity: 0.5;
}

.dashboard-portlet .table {
    margin-bottom: 0rem;
}

.dashboard-portlet {
    max-height: 750px;
    min-height: 750px;
    height: 750px;
}

.btn.btn-white {
    color: $brand;
    background-color: $white;
}

.btn.btn-text-white {
    color: $white;
}

.btn.btn-link.no-underline:hover {
    text-decoration: none;
}

.btn.btn-brand {
    background-color: $brand;
}

.btn.btn-brand:not(:disabled):not(.disabled):active {
    background-color: $brand;
    border-color: $brand;
}

.btn.disabled {
    cursor: pointer;
}

.dashboard-service-ad {
    text-align: center;

    img {
        display: block;
        margin: 1rem auto;
        max-width: 145px;
        min-width: 145px;
    }

    .dashboard-service-ad__title {
        font-weight: 600;
        font-size: 22px;
        color: #575962;
    }

    .dashboard-service-ad__subtitle {
        font-weight: 500;
        font-size: 14px;
        color: #868e96;
    }

    .dashboard-service-ad__desc {
        margin: 2.5rem 0 0 0;
        font-weight: 400;
        font-size: 12px;
        color: #575962;
        line-height: 20px;
    }

    .dashboard-service-ad__actions > :not(:last-child) {
        margin-right: 0.25rem;
    }

    .btn-sm {
        padding: 0.45rem 3rem;
    }
}

.m-portlet .m-portlet__foot:not(.m-portlet__no-border) {
    border: none;
}

.m-page--loading-non-block {
    .toggle-aside-ul {
        display: none;
    }
}

.toggle-aside-ul {
    top: 50%;
    left: 255px;
    border-radius: 0 8px 8px 0;
    width: 27px;
    padding: 30px 0;
    box-shadow: none;
    transition: left 0.2s ease;
    background: #fff;
    position: fixed;
    right: 0;
    list-style: none;
    margin: 0;
    z-index: 110;
    .toggle-aside-ul__item {
        padding: 2px;
        margin: 0;
        text-align: center;
        a > i {
            color: #c1bfd0;
        }
        a:hover > i {
            color: #837dd1;
        }
    }
}

.toggle-aside-ul .toggle-aside-ul__item {
    padding: 2px;
}

.m-aside-left--minimize .toggle-aside-ul {
    left: 78px;
}
.m-aside-left--minimize-hover .toggle-aside-ul {
    display: none;
}

.m-aside-left.m-aside-left--skin-brand {
    background-color: $white;
}

.m-aside-menu.m-aside-menu--skin-brand .m-menu__nav > .m-menu__item {
    background-color: transparent;
    margin: 0 10px;

    a {
        color: $brand;
    }
}

.m-aside-menu.m-aside-menu--skin-brand
    .m-menu__nav
    > .m-menu__item.m-menu__item--active {
    background-color: $background;

    a {
        color: $brand;
    }
}

.m-aside-menu.m-aside-menu--skin-brand
    .m-menu__nav
    > .m-menu__item:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover {
    transition: background-color 0.3s;
    background-color: $background;

    a {
        transition: color 0.3s;
        color: $brand;
    }
}

@media (max-width: 1024px) {
    .toggle-aside-ul {
        display: none;
    }

    .m-aside-left-close.m-aside-left-close--skin-brand {
        background-color: transparent;
        i {
            color: $brand;
        }
    }

    .m-aside-left-close.m-aside-left-close--skin-brand:hover {
        background-color: $brand;
        i {
            color: $white;
        }
    }
}

.m-aside-menu .m-menu__nav .m-menu__item.m-menu__item--open > .m-menu__submenu {
    display: block;
}

.modal-header.service-header {
    border: none;

    h5 {
        color: $darkgrey;
    }

    .service-header__container {
        display: flex;
        width: 100%;
        justify-content: flex-start;

        img {
            max-width: 125px;
            max-height: 125px;
            min-width: 125px;
            min-height: 125px;
            margin-right: 20px;
        }

        .service-header__container-text {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.modal-footer.service-footer {
    border: none;
}

.modal .modal-content {
    border-radius: 0;
}

.modal-body h6 {
    color: $brand;
}

.content-subheader__title {
    font-weight: 500;
    font-size: 1.55rem;
    vertical-align: middle;
    padding: 7px 25px 7px 0;
}

.m-subheader .m-subheader__title {
    font-family: Arial;
}

.m-subheader__title2 {
    font-size: 1.40rem;
    font-weight: 500;
    margin-bottom: 25px;
}

@media (max-width: 1200px) {
    .m-nav .m-nav__item > .m-nav__link .m-nav__link-text {
        font-size: 0.9rem;
    }

    .m-nav.m-nav--inline > .m-nav__item {
        padding-left: 10px;
    }
}

.m-footer {
    height: auto;
}

.m-content--skin-light2 .m-body {
    background-color: #f1f0ec;
}

.m-brand {
    background-color: $brand;
    a,
    a:hover {
        text-decoration: none;
    }
}

.m-brand__logo {
    a {
        white-space: nowrap;
        text-overflow: clip;
        display: flex !important;
        img {
            height: 40px;
            width: 40px;
        }

        span {
            font-family: Arial;
            font-size: 1.8rem;
            font-weight: 800;
            color: white;
            margin-left: 10px;
            line-height: 40px;
            white-space: nowrap;
            text-overflow: clip;
            display: block;
        }
    }
}

.m-brand__tools {
    a {
        img {
            display: none;
            height: 40px;
            width: 40px;
        }
    }
}

.m-brand--minimize .m-brand__tools {
    a {
        img {
            display: inline;
        }
    }
}

@media (min-width: 1025px) {
    .m-header-menu {
        height: 70px;
    }
}

@media (min-width: 1025px) {
    .m-brand__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .m-brand__logo {
        width: auto;
    }

    .m-header--fixed-mobile .m-topbar {
        top: -70px;
    }
}

.m-portlet__body .m-portlet__body-inner {
    max-height: 630px;
    overflow-y: auto;
}

.loader-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}
.loader {
    font-size: 10px;
    position: relative;
    border-top: 0.1em solid rgba(241, 240, 236, 0.5);
    border-right: 0.1em solid rgba(241, 240, 236, 0.5);
    border-bottom: 0.1em solid rgba(241, 240, 236, 0.5);
    border-left: 0.1em solid #4F2D7F;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.report-container {
    div {
        height: calc(40vw);
    }
    iframe {
        border: none;
    }
}

.m-aside-left--minimize .report-container {
    div {
        height: calc(40vw);
    }
}

.report-container-fullscreen {
    div {
        height: 98%;
    }
}

.toggle-aside-ul a {
    cursor: pointer;
}

.gt-link-highlight {
    font-weight: 700;
}

.svg-rocket {
    width: 32px;
    margin-left: -12px;
    margin-right: 6px;
}

.svg-rocket-small {
    width: 40px;
    height: 40px;
}

.svg-magnifying-glass {
    width: 32px;
    margin-left: -12px;
    margin-right: 6px;
}

.svg-magnifying-glass-small {
    width: 40px;
    height: 40px;
}
